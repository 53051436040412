import type { PromotionItem } from '@cms/types/MagentoPage.types'
import type { ProductInterface } from '#gql'

export function getProductCurrentCmsPromotions({ currentPromotions, product }: { currentPromotions: PromotionItem[], product: Pick<ProductInterface, 'sku' | 'categories'> | null }) {
  const data = {
    isInAnyPromotion: false,
    promotionsList: [],
  }
  if (!product || !currentPromotions?.length) {
    data
  }

  const categoriesUids = (product?.categories ?? []).map(category => category?.uid ?? '')

  const list: PromotionItem[] = []

  const promoBySku = currentPromotions.filter(promotion => (promotion?.products ?? []).includes(product?.sku ?? ''))

  if (promoBySku.length) {
    list.push(...promoBySku)
  }

  categoriesUids.forEach((element) => {
    const promoByCategoryUid = currentPromotions.filter(promotion => promotion.categories?.includes(element))

    if (!promoByCategoryUid.length) {
      return
    }

    list.push(...promoByCategoryUid)
  })

  Object.assign(data, { isInAnyPromotion: Boolean(list.length), promotionsList: list })

  return data
}
